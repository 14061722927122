<template>
  <div :class="`all-properties ${$isMobile ? 'is-mobile' : ''}`">
    <WelcomeMessage/>
    <div class="sticky-header has-background-white">
      <MenuAndLogo :showReferral="true" referralIconTracker="9h7kuo26"></MenuAndLogo>
    </div>
    <section class="scrolling-part">
      <div class="properties-for-sale">
        <div class="section-title has-text-weight-semibold">Properties for sale</div>
        <div class="current-properties" v-if="propertiesForSale.length">
          <div class="property is-clickable" v-for="(property, index) in propertiesForSale" :key="index" @click="toPropertyForSale(property.nameSpaceUrl, property.hashid)">
            <div class="property-preview is-relative" :style="`background: url(${property.primaryImage}) center/cover no-repeat;`">
              <div class="type-of-investment is-flex is-align-items-center" v-if="property.typeIconUrl">
                <img :src="property.typeIconUrl" alt="type of investment">
                <span class="has-text-blue type">{{property.typeOfInvestment}}</span>
              </div>
            </div>
            <div class="property-nickname is-uppercase has-text-weight-semibold is-flex is-justify-content-space-between">
              <span class="nickname is-inline-block">{{property.nickname}}</span>
              <span class="currency has-text-blue has-text-weight-bold px-3">{{property.country === 'Canada' ? 'CAD' : 'USD'}}</span>
            </div>
            <div :class="`property-name ${property.status === 'pre_sale' ? 'has-mb-18' : 'has-mb-32'}`">{{property.name}} {{property.country === 'Canada' ? '🇨🇦' : '🇺🇸'}}</div>
            <template v-if="property.status === 'sale'">
              <template v-if="property.isTimeBased">
                <div class="dynamic-text has-mb-2">{{getTimeBasedText(property.purchasedRate)}} ⏱</div>
              </template>
              <template v-else>
                <div
                  class="dynamic-text has-mb-2"
                  v-if="property.remainingForSale"
                >${{property.listPrice - property.purchased | formatNumber}} remaining of ${{property.listPrice | formatNumber}} 💰</div>
                <div
                  class="dynamic-text has-mb-2"
                  v-else
                >${{property.purchased | formatNumber}} of ${{property.listPrice | formatNumber}} sold 💰</div>
              </template>
            </template>
            <template v-else-if="property.status === 'pre_sale'">
              <div class="dynamic-text">{{property.preOfferingHeader}}: ${{property.listPrice | formatNumber}}</div>
              <div class="dynamic-text has-mb-2">Come back soon for the drop 😎</div>
            </template>
            <template v-else>
              <div class="dynamic-text has-mb-2">${{property.listPrice | formatNumber}} of ${{property.listPrice | formatNumber}} sold</div>
            </template>
            <div class="progress-bar">
              <div class="inner-bar has-background-blue" :style="`width: ${Math.min(100, property.purchasedRate)}%;`" v-if="property.status === 'sale'"></div>
              <div class="inner-bar has-background-blue" style="width: 100%;" v-if="['post_sale', 'reconciliation', 'settled', 'exited'].includes(property.status)"></div>
            </div>
            <div class="bottom is-flex is-justify-content-space-between is-align-items-center">
              <div class="status-tag has-text-blue has-text-weight-semibold">{{property.parsedStatus}}!</div>
              <div class="buy has-text-weight-semibold is-uppercase" v-if="property.status === 'sale'">Buy property <i class="fas fa-chevron-right"></i></div>
            </div>
          </div>
        </div>
        <div class="empty-state" v-else>
          <div class="placeholder-text has-text-weight-semibold has-text-centered line1">There are no active properties.</div>
          <div class="placeholder-text has-text-weight-semibold has-text-centered line2">But stay tuned for announcements!</div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import WelcomeMessage from '@components/desktop/header-welcome-message.vue'
import { getAllProperties } from '@api/properties'
import { getMembershipStatus } from '@api/common'
import { formatter } from '@utils/common-methods/numberFormatter'
import { mapState } from 'vuex'

export default {
  components: {
    MenuAndLogo,
    WelcomeMessage,
  },
  data() {
    return {
      propertiesForSale: [],
      membershipPaid: false,
    }
  },
  computed: {
    ...mapState('mixpanel', ['mixpanel']),
  },
  filters: {
    formatNumber(value) {
      return formatter(value, 0)
    },
  },
  created() {
    this.fetchData()
    this.getMembershipPayStatus()
  },
  methods: {
    fetchData() {
      getAllProperties().then((result) => {
        if (!result.success) return
        const arr = JSON.parse(JSON.stringify(result.data))
        arr.map((prop) => {
          prop.parsedStatus = this.getParsedStatus(prop.status, prop.purchased, prop.listPrice)
          prop.typeIconUrl = this.getTypeIconUrl(prop.typeOfInvestment)
          prop.purchasedRate = prop.purchased / prop.listPrice * 100
        })
        this.propertiesForSale = arr
      })
    },
    getMembershipPayStatus() {
      getMembershipStatus().then((result) => {
        if (!result.success) return
        this.membershipPaid = result.data.membershipPaid
      })
    },
    getParsedStatus(status, purchased, listPrice) {
      if (status === 'sale') {
        const purchasedRate = purchased / listPrice * 100
        if (purchasedRate >= 85) return 'Almost gone'
        else return 'Just dropped'
      } else if (status === 'pre_sale') {
        return 'Dropping soon'
      } else if (status === 'exited') {
        return 'Exited'
      } else {
        return 'Sold out'
      }
    },
    getTypeIconUrl(type) {
      switch (type) {
      case 'Value-Add':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-value-add.svg'
      case 'Opportunistic':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-opportunistic.svg'
      case 'Core':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-core.svg'
      case 'Core Plus':
        return 'https://addy-public.s3.us-west-2.amazonaws.com/investment-core-plus.svg'
      }
    },
    getTimeBasedText(purchasedRate) {
      let text = ''
      const totalHoursRemaining = 24 * 15 * (1 - purchasedRate / 100)
      let days = Math.floor(totalHoursRemaining / 24)
      let hours = Math.ceil(totalHoursRemaining % 24)
      if (hours === 24) {
        days++
        hours = 0
      }
      if (!days) {
        text = hours + (hours > 1 ? ' hours' : ' hour') + ' remaining'
      } else {
        if (hours) text = days + (days > 1 ? ' days ' : ' day ') + hours + (hours > 1 ? ' hours' : ' hour') + ' remaining'
        else text = days + (days > 1 ? ' days remaining' : ' day remaining')
      }
      return 'Potentially ' + text
    },
    toPropertyForSale(nameSpaceUrl, hashid) {
      const tracker = this.membershipPaid ? 'tor9m95t' : 'dbusvqev'
      this.mixpanel.track(tracker)

      this.$router.push(`${nameSpaceUrl}/buy/${hashid}`)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.all-properties {
  &.is-mobile {
    padding-top: 68px;
  }
  background-color: #fafafa;
  min-height: 100vh;
  overflow: auto;
  .sticky-header {
    width: 100%;
    height: 68px;
    box-shadow: 0px 0px 12px rgba(45, 41, 38, 0.05);
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
  }
  .scrolling-part {
    .properties-for-sale {
      padding: 16px 25px;
      .section-title {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 16px;
      }
      .current-properties {
        .property {
          border-radius: 12px;
          margin-bottom: 16px;
          padding: 12px;
          border-radius: 12px;
          background-color: #F0EEF8;
          .property-preview {
            height: 87px;
            border-radius: 8px;
            overflow: hidden;
            .type-of-investment {
              height: 27px;
              position: absolute;
              right: 0;
              bottom: 0;
              border-top-left-radius: 12px;
              padding: 0 10px 0 7px;
              background-color: rgba(255, 255, 255, 0.7);
              .type {
                font-size: 14px;
                margin-left: 4px;
              }
            }
          }
          .property-nickname {
            font-size: 16px;
            line-height: 19px;
            padding: 10px 0 4px;
            .nickname {
              flex: 1;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          .property-name {
            font-size: 16px;
            line-height: 19px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            &.has-mb-32 {
              margin-bottom: 32px;
            }
            &.has-mb-18 {
              margin-bottom: 18px;
            }
          }
          .dynamic-text {
            color: #9185AE;
            line-height: 14px;
            &.has-mb-2 {
              margin-bottom: 2px;
            }
          }
          .progress-bar {
            background-color: #FDFDFD;
            border-radius: 4px;
            height: 14px;
            padding: 3px;
            margin-bottom: 19px;
            .inner-bar {
              border-radius: 2px;
              height: 100%;
            }
          }
          .bottom {
            .status-tag {
              font-size: 14px;
              padding: 7px 15px;
              background-color: rgba(255, 255, 255, 0.5);
              border-radius: 6px;
            }
            .buy {
              font-size: 14px;
              color: #3CDBC0;
              .fa-chevron-right {
                color: #3CDBC0;
                font-size: 12px;
                margin-left: 4px;
              }
            }
          }
        }
      }
      .empty-state {
        height: 276px;
        background: url('../../../assets/images/properties/property-empty-state.png') center/contain no-repeat;
        border: 1px solid #F0EEF8;
        border-radius: 12px;
        .placeholder-text {
          font-size: 16px;
          line-height: 19px;
          color: #9185AE;
          &.line1 {
            margin-top: 119px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .all-properties {
    background-color: transparent;
  }
}
</style>
